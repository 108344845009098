<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Envoyé une notification</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-form-input
              v-model="message"
              placeholder="Message"
              type="text"
              required
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="show === true"
            >
              <strong> Message is required </strong>
            </p>
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-button variant="primary" block @click="sendMessage()">
              Envoyé
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card-actions action-collapse title="Liste des notifications">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!--<label class="mr-1">Recherche</label>-->
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block mr-1"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-cart"
              @click="deletedSelected()"
            >
              <feather-icon icon="TrashIcon" class="" />
            </b-button>
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'Label'" class="text-nowrap">
            <span>
              <feather-icon :icon="props.row.icon" /> {{ props.row.label }}
            </span>
          </span>
          <span v-else-if="props.column.label === 'Action'" class="text-nowrap">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="deleteNotifcation(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import feather from "../feather/feather";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import CategoryMixin from "@/mixins/category.mixin";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useClipboard } from "@vueuse/core";
import { useToast } from "vue-toastification/composition";
export default {
  mixins: [CategoryMixin],

  components: {
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BCardActions,
    BFormInput,
    ToastificationContent,
    feather,
    BFormGroup,
    BFormInvalidFeedback,
    BListGroup,
    BListGroupItem,
    BAlert,
    BModal,
    VBModal,
    BDropdown,
    BDropdownItem,
    BButton,

    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      seachQuery: "",
      show: false,
      name: "",
      nameState: null,
      submittedNames: [],
      label: "",
      icon: "",
      toEdit: {},
      message:"",
      columns: [
        {
          label: "Message",
          field: "message",
        },
        {
          label: "Action",
          field: "label",
        },
      ],
      rows: [],
    };
  },

  methods: {
    deletedSelected() {
      console.log("hi selected ", this.$refs["table-category"].selectedRows);
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          (async () => {
            this.$refs["table-category"].selectedRows.forEach(async (c) => {
              await this.$store.dispatch("DeleteCategory", c).then((data) => {
                if (data.data != "") {
                  this.rows = [];
                  this.rows = this.categories;
                }
              });
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfylly Deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
          })();
          /*this.$store.dispatch("DeleteCategory", category).then((data) => {
            if (data.data != "") {
              this.rows = [];

              this.rows = this.categories;
            }
          });*/
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annuler la suppression ",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    // search category
    advanceSearch(val) {
      this.searchTerm = val;
    },
  
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store.dispatch("UpdateCategory", this.toEdit).then((data) => {
        if (data._id !== undefined) {
          this.toEdit = {};
          this.rows = [];
          let self = this;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Category Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          setTimeout(function () {
            self.rows = self.categories;
          }, 1000);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Category failed",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    sendMessage() {
      console.log("test message ", this.message);
      if (this.message) {
        this.show = true;
      } else {
        this.$store.dispatch("SendNotification", this.message).then((data) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification envoyé",
              icon: "PlusIcon",
              variant: "success",
            },
          });
          this.message= "";
          this.show = false;
        });
      }
    },

    _editCategory(category) {
      this.toEdit = Object.assign({}, category);
    },
    deleteNotifcation(category) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteNotifcation", category).then((data) => {
            if (data.data != "") {
              this.rows = [];

              this.rows = this.categories;
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annuler la suppression ",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
  mounted() {
    this.rows = this.categories;
    console.log("rows ", this.rows);
  },

  watch: {
    categories: function () {
      this.rows = this.categories;
      console.log("rows 2", this.rows);
    },
    category: function () {
      if (this.category.label !== "") {
        this.show = false;
      }
    },
  },

  computed: {
    filterIcons() {
      const seachQueryIcon = this.seachQuery.toLowerCase();
      return this.icons.filter((item) =>
        item.toLowerCase().includes(seachQueryIcon)
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>